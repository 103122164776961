<template lang="">
    <div>
        <!-- <p class="error">{{ error }}</p>

<p class="decode-result">Last result: <b>{{ result }}</b></p> -->

        <qrcode-stream @decode="onDecode" @init="onInit" ></qrcode-stream>
        <!-- <qrcode-drop-zone></qrcode-drop-zone>
        <qrcode-capture></qrcode-capture> -->
    </div>
</template>

<script>

import { QrcodeStream} from 'vue-qrcode-reader'
export default {
  created () {
    let login = localStorage.getItem("Login");
        this.user = JSON.parse(login);
        if(this.user.Auth_Login=="1"){
            this.$router.push('/qradmin/login')
        }
  },
  watch: {
  },
    data() {
        return {
            result: '',
            error: '',
            user:[]
        }
    },
    components: {
        QrcodeStream 
    },
    methods: {
        onDecode(result) {
            this.result = result
            this.$router.push('/queueinfo?qr='+result)
        },

        async onInit(promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permission"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                } else if (error.name === 'InsecureContextError') {
                    this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                } else {
                    this.error = `ERROR: Camera error (${error.name})`;
                }
            }
        }
    },

}
</script>

<style lang="">

</style>